const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['common'] = require('../middleware/common.ts')
middleware['common'] = middleware['common'].default || middleware['common']

middleware['errorHandler'] = require('../middleware/errorHandler.ts')
middleware['errorHandler'] = middleware['errorHandler'].default || middleware['errorHandler']

middleware['exposure'] = require('../middleware/exposure.ts')
middleware['exposure'] = middleware['exposure'].default || middleware['exposure']

middleware['i18n'] = require('../middleware/i18n.ts')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['pageCache'] = require('../middleware/pageCache.ts')
middleware['pageCache'] = middleware['pageCache'].default || middleware['pageCache']

middleware['pageCache1'] = require('../middleware/pageCache1.ts')
middleware['pageCache1'] = middleware['pageCache1'].default || middleware['pageCache1']

export default middleware

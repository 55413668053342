import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _62050a68 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "" */))
const _70ee0ad1 = () => interopDefault(import('../pages/fair/index.vue' /* webpackChunkName: "" */))
const _7dc7213e = () => interopDefault(import('../pages/fair/index-seo.vue' /* webpackChunkName: "" */))
const _462c9f9d = () => interopDefault(import('../pages/fair/exporec.vue' /* webpackChunkName: "" */))
const _8fccb746 = () => interopDefault(import('../pages/wiki/index.vue' /* webpackChunkName: "" */))
const _2615d7e3 = () => interopDefault(import('../pages/wiki/list.vue' /* webpackChunkName: "" */))
const _39241254 = () => interopDefault(import('../pages/wiki/detail.vue' /* webpackChunkName: "" */))
const _6528398d = () => interopDefault(import('../pages/fair/top100.vue' /* webpackChunkName: "" */))
const _5faf0f3f = () => interopDefault(import('../pages/fair/detail1.vue' /* webpackChunkName: "" */))
const _b46ce662 = () => interopDefault(import('../pages/fair/detail1-seo.vue' /* webpackChunkName: "" */))
const _635f1e9e = () => interopDefault(import('../pages/fair/pdf.vue' /* webpackChunkName: "" */))
const _b9c730d2 = () => interopDefault(import('../pages/fair/periodical.vue' /* webpackChunkName: "" */))
const _ece15f10 = () => interopDefault(import('../pages/fair/periodical_new.vue' /* webpackChunkName: "" */))
const _c2362c7c = () => interopDefault(import('../pages/booth/index1.vue' /* webpackChunkName: "" */))
const _77f91a92 = () => interopDefault(import('../pages/trip/index.vue' /* webpackChunkName: "" */))
const _5cb36d1c = () => interopDefault(import('../pages/community/index.vue' /* webpackChunkName: "" */))
const _4a30efb0 = () => interopDefault(import('../pages/exhDetailInf/index.vue' /* webpackChunkName: "" */))
const _0bda2bd0 = () => interopDefault(import('../pages/fair/proxyExh.vue' /* webpackChunkName: "" */))
const _46a5929a = () => interopDefault(import('../pages/company/list.vue' /* webpackChunkName: "" */))
const _1ef9dfcd = () => interopDefault(import('../pages/company/detail.vue' /* webpackChunkName: "" */))
const _011247c6 = () => interopDefault(import('../pages/fair/ticket.vue' /* webpackChunkName: "" */))
const _21238d38 = () => interopDefault(import('../pages/fair/ticket1.vue' /* webpackChunkName: "" */))
const _161971a2 = () => interopDefault(import('../pages/news/index2.vue' /* webpackChunkName: "" */))
const _74a7da2d = () => interopDefault(import('../pages/pavilion/index.vue' /* webpackChunkName: "" */))
const _8eb309f4 = () => interopDefault(import('../pages/pavilion/detail.vue' /* webpackChunkName: "" */))
const _a356505a = () => interopDefault(import('../pages/news/detail.vue' /* webpackChunkName: "" */))
const _2470eb63 = () => interopDefault(import('../pages/news/detail-seo.vue' /* webpackChunkName: "" */))
const _45b9a272 = () => interopDefault(import('../pages/news/exponews.vue' /* webpackChunkName: "" */))
const _0592a857 = () => interopDefault(import('../pages/news/exponews-seo.vue' /* webpackChunkName: "" */))
const _166a4f75 = () => interopDefault(import('../pages/news/strategy.vue' /* webpackChunkName: "" */))
const _d48963d8 = () => interopDefault(import('../pages/news/test.vue' /* webpackChunkName: "" */))
const _2dcada04 = () => interopDefault(import('../pages/cooperation/index.vue' /* webpackChunkName: "" */))
const _6ca36fae = () => interopDefault(import('../pages/visa/index.vue' /* webpackChunkName: "" */))
const _7fc4d4b6 = () => interopDefault(import('../pages/visa/detail.vue' /* webpackChunkName: "" */))
const _7daeb01c = () => interopDefault(import('../pages/visa/submitOrder.vue' /* webpackChunkName: "" */))
const _8563c382 = () => interopDefault(import('../pages/airTicket/index.vue' /* webpackChunkName: "" */))
const _ed4ccc7e = () => interopDefault(import('../pages/airTicket/list.vue' /* webpackChunkName: "" */))
const _45ff347a = () => interopDefault(import('../pages/airTicket/submitOrder.vue' /* webpackChunkName: "" */))
const _02ab0852 = () => interopDefault(import('../pages/airTicket/payResult.vue' /* webpackChunkName: "" */))
const _238daff2 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "" */))
const _5bec0c2a = () => interopDefault(import('../pages/expouser/index.vue' /* webpackChunkName: "" */))
const _669e7c90 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "" */))
const _d001c42a = () => interopDefault(import('../pages/about/advertise.vue' /* webpackChunkName: "" */))
const _1ca50716 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "" */))
const _8d7e6a84 = () => interopDefault(import('../pages/sitemap/index.vue' /* webpackChunkName: "" */))
const _38620b65 = () => interopDefault(import('../pages/pay/payment.vue' /* webpackChunkName: "" */))
const _093094e4 = () => interopDefault(import('../pages/pay/result.vue' /* webpackChunkName: "" */))
const _7b0c0a41 = () => interopDefault(import('../pages/visa/payResult.vue' /* webpackChunkName: "" */))
const _50ce5094 = () => interopDefault(import('../pages/manager/index.vue' /* webpackChunkName: "" */))
const _82714cd6 = () => interopDefault(import('../pages/manager/fairRemind.vue' /* webpackChunkName: "" */))
const _4f947e41 = () => interopDefault(import('../pages/manager/fairFollow.vue' /* webpackChunkName: "" */))
const _192aa330 = () => interopDefault(import('../pages/manager/newsFollow.vue' /* webpackChunkName: "" */))
const _011269aa = () => interopDefault(import('../pages/manager/newsMy.vue' /* webpackChunkName: "" */))
const _9f4ac0b8 = () => interopDefault(import('../pages/manager/ticketMy.vue' /* webpackChunkName: "" */))
const _6666578d = () => interopDefault(import('../pages/manager/visa.vue' /* webpackChunkName: "" */))
const _9ad99938 = () => interopDefault(import('../pages/manager/hotelMy.vue' /* webpackChunkName: "" */))
const _2482b04c = () => interopDefault(import('../pages/manager/airTicket.vue' /* webpackChunkName: "" */))
const _f65381c4 = () => interopDefault(import('../pages/manager/periodicalMy.vue' /* webpackChunkName: "" */))
const _28e2d6bc = () => interopDefault(import('../pages/manager/fairMy.vue' /* webpackChunkName: "" */))
const _2f2aba3d = () => interopDefault(import('../pages/manager/usersFollow.vue' /* webpackChunkName: "" */))
const _1b397868 = () => interopDefault(import('../pages/manager/usersFans.vue' /* webpackChunkName: "" */))
const _5dcfed74 = () => interopDefault(import('../pages/manager/auth.vue' /* webpackChunkName: "" */))
const _7d5f6cd0 = () => interopDefault(import('../pages/manager/updatePwd.vue' /* webpackChunkName: "" */))
const _8754e33a = () => interopDefault(import('../pages/manager/updateInfo.vue' /* webpackChunkName: "" */))
const _655993f6 = () => interopDefault(import('../pages/manager/industryMy.vue' /* webpackChunkName: "" */))
const _09709252 = () => interopDefault(import('../pages/manager/visitor.vue' /* webpackChunkName: "" */))
const _68d5e27c = () => interopDefault(import('../pages/manager/visitorEdit.vue' /* webpackChunkName: "" */))
const _61efac7c = () => interopDefault(import('../pages/special/index.vue' /* webpackChunkName: "" */))
const _1b54779c = () => interopDefault(import('../pages/system/index.vue' /* webpackChunkName: "" */))
const _5f1a95d2 = () => interopDefault(import('../pages/system/error.vue' /* webpackChunkName: "" */))
const _2402f67e = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "" */))
const _274cb002 = () => interopDefault(import('../pages/booth/index.vue' /* webpackChunkName: "pages/booth/index" */))
const _3a842fc0 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _0815aee8 = () => interopDefault(import('../pages/about/data/index.ts' /* webpackChunkName: "pages/about/data/index" */))
const _3b285f5e = () => interopDefault(import('../pages/airTicket/helper.js' /* webpackChunkName: "pages/airTicket/helper" */))
const _08dd8942 = () => interopDefault(import('../pages/airTicket/mock.js' /* webpackChunkName: "pages/airTicket/mock" */))
const _b7b34ac8 = () => interopDefault(import('../pages/airTicket/pay-mixin.ts' /* webpackChunkName: "pages/airTicket/pay-mixin" */))
const _5bcffb23 = () => interopDefault(import('../pages/airTicket/service.ts' /* webpackChunkName: "pages/airTicket/service" */))
const _e623d270 = () => interopDefault(import('../pages/airTicket/type.ts' /* webpackChunkName: "pages/airTicket/type" */))
const _40b05bb6 = () => interopDefault(import('../pages/airTicket/validate.js' /* webpackChunkName: "pages/airTicket/validate" */))
const _6a33ea54 = () => interopDefault(import('../pages/booth/helper.ts' /* webpackChunkName: "pages/booth/helper" */))
const _45265ee2 = () => interopDefault(import('../pages/fair/detail.vue' /* webpackChunkName: "pages/fair/detail" */))
const _73986314 = () => interopDefault(import('../pages/fair/index copy.vue' /* webpackChunkName: "pages/fair/index copy" */))
const _a62a0e9e = () => interopDefault(import('../pages/fair/index2.vue' /* webpackChunkName: "pages/fair/index2" */))
const _39d93456 = () => interopDefault(import('../pages/fair/periodicalList.vue' /* webpackChunkName: "pages/fair/periodicalList" */))
const _318b1113 = () => interopDefault(import('../pages/fair/player.ts' /* webpackChunkName: "pages/fair/player" */))
const _1c2da0d1 = () => interopDefault(import('../pages/fair/service.ts' /* webpackChunkName: "pages/fair/service" */))
const _01078307 = () => interopDefault(import('../pages/fair/ticket_free.ts' /* webpackChunkName: "pages/fair/ticket_free" */))
const _21075e36 = () => interopDefault(import('../pages/fair/ticket2.vue' /* webpackChunkName: "pages/fair/ticket2" */))
const _ac9f16b2 = () => interopDefault(import('../pages/fair/topData.ts' /* webpackChunkName: "pages/fair/topData" */))
const _20e0d142 = () => interopDefault(import('../pages/home/data/index.ts' /* webpackChunkName: "pages/home/data/index" */))
const _2c705ede = () => interopDefault(import('../pages/login/constant.ts' /* webpackChunkName: "pages/login/constant" */))
const _8a85f994 = () => interopDefault(import('../pages/news/newsType.ts' /* webpackChunkName: "pages/news/newsType" */))
const _591f3795 = () => interopDefault(import('../pages/pay/pay-periodical-mixin.ts' /* webpackChunkName: "pages/pay/pay-periodical-mixin" */))
const _14735c4f = () => interopDefault(import('../pages/pay/pay-ticket-mixin.ts' /* webpackChunkName: "pages/pay/pay-ticket-mixin" */))
const _2b049e66 = () => interopDefault(import('../pages/visa/helper.js' /* webpackChunkName: "pages/visa/helper" */))
const _aed31012 = () => interopDefault(import('../pages/visa/pay-visa-mixin.ts' /* webpackChunkName: "pages/visa/pay-visa-mixin" */))
const _d1b650d8 = () => interopDefault(import('../pages/visa/service.ts' /* webpackChunkName: "pages/visa/service" */))
const _ca121012 = () => interopDefault(import('../pages/visa/type.ts' /* webpackChunkName: "pages/visa/type" */))
const _6ff70d65 = () => interopDefault(import('../pages/visa/validate.js' /* webpackChunkName: "pages/visa/validate" */))
const _fb53f00a = () => interopDefault(import('../pages/airTicket/component/Baggage.vue' /* webpackChunkName: "pages/airTicket/component/Baggage" */))
const _0561f897 = () => interopDefault(import('../pages/airTicket/component/CabinItem.vue' /* webpackChunkName: "pages/airTicket/component/CabinItem" */))
const _21e0184b = () => interopDefault(import('../pages/airTicket/component/ListFilter.vue' /* webpackChunkName: "pages/airTicket/component/ListFilter" */))
const _a55055b4 = () => interopDefault(import('../pages/airTicket/component/ListItem.vue' /* webpackChunkName: "pages/airTicket/component/ListItem" */))
const _456823ae = () => interopDefault(import('../pages/airTicket/component/RefundRule.vue' /* webpackChunkName: "pages/airTicket/component/RefundRule" */))
const _08332854 = () => interopDefault(import('../pages/booth/components/card.vue' /* webpackChunkName: "pages/booth/components/card" */))
const _9243c36c = () => interopDefault(import('../pages/company/component/pay.vue' /* webpackChunkName: "pages/company/component/pay" */))
const _172b2f3a = () => interopDefault(import('../pages/fair/component/booth.vue' /* webpackChunkName: "pages/fair/component/booth" */))
const _ce279444 = () => interopDefault(import('../pages/fair/component/booth1.vue' /* webpackChunkName: "pages/fair/component/booth1" */))
const _52596e66 = () => interopDefault(import('../pages/fair/component/content.vue' /* webpackChunkName: "pages/fair/component/content" */))
const _67ee0bd4 = () => interopDefault(import('../pages/fair/component/detailTopBg.vue' /* webpackChunkName: "pages/fair/component/detailTopBg" */))
const _c2023320 = () => interopDefault(import('../pages/fair/component/dialogSubsidy.vue' /* webpackChunkName: "pages/fair/component/dialogSubsidy" */))
const _351d43df = () => interopDefault(import('../pages/fair/component/evaluation.vue' /* webpackChunkName: "pages/fair/component/evaluation" */))
const _4c99ed53 = () => interopDefault(import('../pages/fair/component/FairItemSeo.vue' /* webpackChunkName: "pages/fair/component/FairItemSeo" */))
const _6cf11898 = () => interopDefault(import('../pages/fair/component/goodsDetail.vue' /* webpackChunkName: "pages/fair/component/goodsDetail" */))
const _362a031a = () => interopDefault(import('../pages/fair/component/invoice.vue' /* webpackChunkName: "pages/fair/component/invoice" */))
const _68df02be = () => interopDefault(import('../pages/fair/component/newDetail.vue' /* webpackChunkName: "pages/fair/component/newDetail" */))
const _0ab97480 = () => interopDefault(import('../pages/fair/component/newItem.vue' /* webpackChunkName: "pages/fair/component/newItem" */))
const _11b8cf79 = () => interopDefault(import('../pages/fair/component/NewsItem2.vue' /* webpackChunkName: "pages/fair/component/NewsItem2" */))
const _296ccf6e = () => interopDefault(import('../pages/fair/component/orderInfo.vue' /* webpackChunkName: "pages/fair/component/orderInfo" */))
const _38ec5079 = () => interopDefault(import('../pages/fair/component/payDeposit.vue' /* webpackChunkName: "pages/fair/component/payDeposit" */))
const _da2af220 = () => interopDefault(import('../pages/fair/component/poster.vue' /* webpackChunkName: "pages/fair/component/poster" */))
const _165cfe36 = () => interopDefault(import('../pages/fair/component/resourceView.vue' /* webpackChunkName: "pages/fair/component/resourceView" */))
const _3f472c48 = () => interopDefault(import('../pages/fair/component/resourceView-copy.vue' /* webpackChunkName: "pages/fair/component/resourceView-copy" */))
const _2542d554 = () => interopDefault(import('../pages/fair/component/Route.vue' /* webpackChunkName: "pages/fair/component/Route" */))
const _356a67f2 = () => interopDefault(import('../pages/fair/component/ServerStep.vue' /* webpackChunkName: "pages/fair/component/ServerStep" */))
const _7bd466d0 = () => interopDefault(import('../pages/fair/component/subsidy.vue' /* webpackChunkName: "pages/fair/component/subsidy" */))
const _20817a7c = () => interopDefault(import('../pages/fair/formData/nepcon.ts' /* webpackChunkName: "pages/fair/formData/nepcon" */))
const _523c2600 = () => interopDefault(import('../pages/home/component/eveFair.vue' /* webpackChunkName: "pages/home/component/eveFair" */))
const _ba217382 = () => interopDefault(import('../pages/home/component/FairItem.vue' /* webpackChunkName: "pages/home/component/FairItem" */))
const _2e5d52f9 = () => interopDefault(import('../pages/home/component/NewsList.vue' /* webpackChunkName: "pages/home/component/NewsList" */))
const _08562c9f = () => interopDefault(import('../pages/login/component/bind.vue' /* webpackChunkName: "pages/login/component/bind" */))
const _c6cb4362 = () => interopDefault(import('../pages/login/component/code.vue' /* webpackChunkName: "pages/login/component/code" */))
const _3213a7cf = () => interopDefault(import('../pages/login/component/forget.vue' /* webpackChunkName: "pages/login/component/forget" */))
const _53f6db12 = () => interopDefault(import('../pages/login/component/login.vue' /* webpackChunkName: "pages/login/component/login" */))
const _780fa206 = () => interopDefault(import('../pages/login/component/password.vue' /* webpackChunkName: "pages/login/component/password" */))
const _3c615f05 = () => interopDefault(import('../pages/login/component/register.vue' /* webpackChunkName: "pages/login/component/register" */))
const _8fade6f0 = () => interopDefault(import('../pages/login/component/wechat.vue' /* webpackChunkName: "pages/login/component/wechat" */))
const _7625679c = () => interopDefault(import('../pages/manager/components/AirTicketItem.vue' /* webpackChunkName: "pages/manager/components/AirTicketItem" */))
const _4f01ce8c = () => interopDefault(import('../pages/manager/components/AirTicketRepayment.vue' /* webpackChunkName: "pages/manager/components/AirTicketRepayment" */))
const _9b1bfba4 = () => interopDefault(import('../pages/manager/components/getIncoive.vue' /* webpackChunkName: "pages/manager/components/getIncoive" */))
const _92ca804c = () => interopDefault(import('../pages/manager/components/HotelItem.vue' /* webpackChunkName: "pages/manager/components/HotelItem" */))
const _732da51b = () => interopDefault(import('../pages/manager/components/lookIncoive.vue' /* webpackChunkName: "pages/manager/components/lookIncoive" */))
const _097804ec = () => interopDefault(import('../pages/manager/components/myUpload.vue' /* webpackChunkName: "pages/manager/components/myUpload" */))
const _a1169134 = () => interopDefault(import('../pages/manager/components/Repayment.vue' /* webpackChunkName: "pages/manager/components/Repayment" */))
const _270eee48 = () => interopDefault(import('../pages/manager/components/TicketItem.vue' /* webpackChunkName: "pages/manager/components/TicketItem" */))
const _6eb81b5e = () => interopDefault(import('../pages/manager/components/VisaItem.vue' /* webpackChunkName: "pages/manager/components/VisaItem" */))
const _1663f725 = () => interopDefault(import('../pages/manager/components/VisaRepayment.vue' /* webpackChunkName: "pages/manager/components/VisaRepayment" */))
const _f153b772 = () => interopDefault(import('../pages/news/component/News.vue' /* webpackChunkName: "pages/news/component/News" */))
const _5bb9d27a = () => interopDefault(import('../pages/news/component/NewsItem.vue' /* webpackChunkName: "pages/news/component/NewsItem" */))
const _14ad362e = () => interopDefault(import('../pages/news/component/RelatedNew.vue' /* webpackChunkName: "pages/news/component/RelatedNew" */))
const _62825bd0 = () => interopDefault(import('../pages/pay/component/poster.vue' /* webpackChunkName: "pages/pay/component/poster" */))
const _30fca94e = () => interopDefault(import('../pages/visa/component/VisaMaterial.vue' /* webpackChunkName: "pages/visa/component/VisaMaterial" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _62050a68,
    name: "首页"
  }, {
    path: "/seo",
    component: _62050a68,
    name: "首页seo"
  }, {
    path: "/exhibition/",
    component: _70ee0ad1,
    name: "国际展会"
  }, {
    path: "/exhibition-:f-:s-:g-:p-:c-:t-:page/",
    component: _70ee0ad1,
    name: "展会列表"
  }, {
    path: "/seo/exhibition/",
    component: _7dc7213e,
    name: "展会列表seo"
  }, {
    path: "/seo/exhibition-:f-:s-:g-:p-:c-:t-:page/",
    component: _7dc7213e,
    name: "展会列表seo2"
  }, {
    path: "/exporec-:f-:page/",
    component: _462c9f9d,
    name: "exporec"
  }, {
    path: "/wiki",
    component: _8fccb746,
    name: "展会百科首页"
  }, {
    path: "/wiki-:s-:page/",
    component: _2615d7e3,
    name: "展会百科列表2"
  }, {
    path: "/wiki/:id-:a-:h-:aid.html",
    component: _39241254,
    name: "展会百科详情页"
  }, {
    path: "/exhibition/top100.html",
    component: _6528398d,
    name: "排行榜"
  }, {
    path: "/exhibition/:id.html",
    component: _5faf0f3f,
    name: "exhibitionDetail"
  }, {
    path: "/seo/exhibition/:id.html",
    component: _b46ce662,
    name: "exhibitionDetail_seo"
  }, {
    path: "/pdf/:id.html",
    component: _635f1e9e,
    name: "展会详情PDF"
  }, {
    path: "/exhibition/periodical/:id.html",
    component: _b9c730d2,
    name: "期刊详情"
  }, {
    path: "/periodical/:id.html",
    component: _ece15f10,
    name: "期刊详情2"
  }, {
    path: "/exhibition/apply/:id.html",
    component: _c2362c7c,
    name: "展会申请"
  }, {
    path: "/exhibition/trip/:id.html",
    component: _77f91a92,
    name: "展团行程"
  }, {
    path: "/community/:id.html",
    component: _5cb36d1c,
    name: "用户社区"
  }, {
    path: "/expoinfo/:id.html",
    component: _4a30efb0,
    name: "展会相关资讯"
  }, {
    path: "/exhibition/proxy/:id.html",
    component: _0bda2bd0,
    name: "代参展"
  }, {
    path: "/exhibitors/:id.html",
    component: _46a5929a,
    name: "参展商名录"
  }, {
    path: "/company/:id.html",
    component: _1ef9dfcd,
    meta: {"isLogin":true},
    name: "参展商详情"
  }, {
    path: "/exhibition/ticket/:id.html",
    component: _011247c6,
    name: "展会门票"
  }, {
    path: "/ticket/:id.html",
    component: _21238d38,
    name: "展会门票2"
  }, {
    path: "/information/",
    component: _161971a2,
    name: "展会资讯"
  }, {
    path: "/pavilion/",
    component: _74a7da2d,
    name: "会展中心"
  }, {
    path: "/pavilion-:f-:s-:g-:p-:c-:t-:page/",
    component: _74a7da2d,
    name: "会展中心-筛选"
  }, {
    path: "/pavilion/:id.html",
    component: _8eb309f4,
    name: "会展中心详情"
  }, {
    path: "/information-:t-:i-:page/",
    component: _161971a2,
    name: "资讯列表"
  }, {
    path: "/information/:id.html",
    component: _a356505a,
    name: "资讯详情"
  }, {
    path: "/seo/information/:id.html",
    component: _2470eb63,
    name: "资讯详情seo"
  }, {
    path: "/exponews/:id.html",
    component: _45b9a272,
    name: "exponews"
  }, {
    path: "/seo/exponews/:id.html",
    component: _0592a857,
    name: "exponewsSeo"
  }, {
    path: "/strategy/:id.html",
    component: _166a4f75,
    name: "strategy"
  }, {
    path: "/test/:id.html",
    component: _d48963d8,
    name: "newsTest"
  }, {
    path: "/cooperation.html",
    component: _2dcada04,
    name: "展会合作"
  }, {
    path: "/visa-:visaAreaId-:areaId-:type-:bizType-:page/",
    component: _6ca36fae,
    name: "签证列表"
  }, {
    path: "/visa/:id.html",
    component: _7fc4d4b6,
    name: "签证详情"
  }, {
    path: "/visa/submitOrder",
    component: _7daeb01c,
    name: "提交订单"
  }, {
    path: "/airTicket",
    component: _8563c382,
    name: "机票首页"
  }, {
    path: "/airTicket/list",
    component: _ed4ccc7e,
    name: "机票列表"
  }, {
    path: "/airTicket/submitOrder",
    component: _45ff347a,
    name: "机票提交订单"
  }, {
    path: "/airTicket/payResult/:status",
    component: _02ab0852,
    name: "机票支付状态"
  }, {
    path: "/login",
    component: _238daff2,
    meta: {"forbidCrawler":false},
    name: "登录"
  }, {
    path: "/expouser/:id.html",
    component: _5bec0c2a,
    name: "用户中心"
  }, {
    path: "/terms",
    component: _669e7c90,
    name: "用户协议"
  }, {
    path: "/about/ad.html",
    component: _d001c42a,
    name: "广告服务"
  }, {
    path: "/about/:id.html",
    component: _1ca50716,
    name: "关于我们"
  }, {
    path: "/sitemap",
    component: _8d7e6a84,
    name: "网站地图"
  }, {
    path: "/payment",
    component: _38620b65,
    meta: {"isLogin":true},
    name: "选择支付方式"
  }, {
    path: "/result/:status",
    component: _093094e4,
    name: "支付状态"
  }, {
    path: "/visa-result/:status",
    component: _7b0c0a41,
    name: "签证支付状态"
  }, {
    path: "/manager",
    component: _50ce5094,
    meta: {"isLogin":true},
    name: "个人中心",
    children: [{
      path: "fair-remind",
      component: _82714cd6,
      name: "展会提醒"
    }, {
      path: "fair-follow",
      component: _4f947e41,
      name: "关注的展会"
    }, {
      path: "news-follow",
      component: _192aa330,
      name: "关注的资讯"
    }, {
      path: "news-my",
      component: _011269aa,
      name: "我的资讯"
    }, {
      path: "ticket-my",
      component: _9f4ac0b8,
      name: "我的门票"
    }, {
      path: "visa-my",
      component: _6666578d,
      name: "我的签证"
    }, {
      path: "hotel-my",
      component: _9ad99938,
      name: "我的酒店"
    }, {
      path: "air-ticket",
      component: _2482b04c,
      name: "我的机票"
    }, {
      path: "periodical-my",
      component: _f65381c4,
      name: "我的会刊"
    }, {
      path: "fair-my",
      component: _28e2d6bc,
      name: "我的展会"
    }, {
      path: "usersFollow",
      component: _2f2aba3d,
      name: "关注的用户"
    }, {
      path: "usersFans",
      component: _1b397868,
      name: "我的粉丝"
    }, {
      path: "auth",
      component: _5dcfed74,
      name: "企业认证"
    }, {
      path: "update-pwd",
      component: _7d5f6cd0,
      name: "修改密码"
    }, {
      path: "update-info",
      component: _8754e33a,
      name: "修改资料"
    }, {
      path: "industry-my",
      component: _655993f6,
      name: "关注的行业"
    }, {
      path: "visitor",
      component: _09709252,
      name: "常用参展人"
    }, {
      path: "visitor-edit/",
      component: _68d5e27c,
      name: "参展人信息"
    }, {
      path: "visitor-edit/:id",
      component: _68d5e27c,
      name: "编辑常用参展人"
    }]
  }, {
    path: "/special/:id.html",
    component: _61efac7c,
    name: "展会专题"
  }, {
    path: "/system/index",
    component: _1b54779c,
    name: "system"
  }, {
    path: "/system/error",
    component: _5f1a95d2,
    name: "customError"
  }, {
    path: "/404",
    component: _2402f67e,
    name: "404"
  }, {
    path: "/404",
    component: _2402f67e,
    name: "404"
  }, {
    path: "/about",
    component: _1ca50716,
    name: "about"
  }, {
    path: "/airTicket",
    component: _8563c382,
    name: "airTicket"
  }, {
    path: "/booth",
    component: _274cb002,
    name: "booth"
  }, {
    path: "/community",
    component: _5cb36d1c,
    name: "community"
  }, {
    path: "/cooperation",
    component: _2dcada04,
    name: "cooperation"
  }, {
    path: "/exhDetailInf",
    component: _4a30efb0,
    name: "exhDetailInf"
  }, {
    path: "/expouser",
    component: _5bec0c2a,
    name: "expouser"
  }, {
    path: "/fair",
    component: _70ee0ad1,
    name: "fair"
  }, {
    path: "/home",
    component: _62050a68,
    name: "home"
  }, {
    path: "/login",
    component: _238daff2,
    name: "login"
  }, {
    path: "/manager",
    component: _50ce5094,
    name: "manager"
  }, {
    path: "/news",
    component: _3a842fc0,
    name: "news"
  }, {
    path: "/pavilion",
    component: _74a7da2d,
    name: "pavilion"
  }, {
    path: "/sitemap",
    component: _8d7e6a84,
    name: "sitemap"
  }, {
    path: "/special",
    component: _61efac7c,
    name: "special"
  }, {
    path: "/system",
    component: _1b54779c,
    name: "system"
  }, {
    path: "/terms",
    component: _669e7c90,
    name: "terms"
  }, {
    path: "/trip",
    component: _77f91a92,
    name: "trip"
  }, {
    path: "/visa",
    component: _6ca36fae,
    name: "visa"
  }, {
    path: "/wiki",
    component: _8fccb746,
    name: "wiki"
  }, {
    path: "/about/advertise",
    component: _d001c42a,
    name: "about-advertise"
  }, {
    path: "/about/data",
    component: _0815aee8,
    name: "about-data"
  }, {
    path: "/airTicket/helper",
    component: _3b285f5e,
    name: "airTicket-helper"
  }, {
    path: "/airTicket/list",
    component: _ed4ccc7e,
    name: "airTicket-list"
  }, {
    path: "/airTicket/mock",
    component: _08dd8942,
    name: "airTicket-mock"
  }, {
    path: "/airTicket/pay-mixin",
    component: _b7b34ac8,
    name: "airTicket-pay-mixin"
  }, {
    path: "/airTicket/payResult",
    component: _02ab0852,
    name: "airTicket-payResult"
  }, {
    path: "/airTicket/service",
    component: _5bcffb23,
    name: "airTicket-service"
  }, {
    path: "/airTicket/submitOrder",
    component: _45ff347a,
    name: "airTicket-submitOrder"
  }, {
    path: "/airTicket/type",
    component: _e623d270,
    name: "airTicket-type"
  }, {
    path: "/airTicket/validate",
    component: _40b05bb6,
    name: "airTicket-validate"
  }, {
    path: "/booth/helper",
    component: _6a33ea54,
    name: "booth-helper"
  }, {
    path: "/booth/index1",
    component: _c2362c7c,
    name: "booth-index1"
  }, {
    path: "/company/detail",
    component: _1ef9dfcd,
    name: "company-detail"
  }, {
    path: "/company/list",
    component: _46a5929a,
    name: "company-list"
  }, {
    path: "/fair/detail",
    component: _45265ee2,
    name: "fair-detail"
  }, {
    path: "/fair/detail1",
    component: _5faf0f3f,
    name: "fair-detail1"
  }, {
    path: "/fair/detail1-seo",
    component: _b46ce662,
    name: "fair-detail1-seo"
  }, {
    path: "/fair/exporec",
    component: _462c9f9d,
    name: "fair-exporec"
  }, {
    path: "/fair/index-seo",
    component: _7dc7213e,
    name: "fair-index-seo"
  }, {
    path: "/fair/index%20copy",
    component: _73986314,
    name: "fair-index copy"
  }, {
    path: "/fair/index2",
    component: _a62a0e9e,
    name: "fair-index2"
  }, {
    path: "/fair/pdf",
    component: _635f1e9e,
    name: "fair-pdf"
  }, {
    path: "/fair/periodical",
    component: _b9c730d2,
    name: "fair-periodical"
  }, {
    path: "/fair/periodical_new",
    component: _ece15f10,
    name: "fair-periodical_new"
  }, {
    path: "/fair/periodicalList",
    component: _39d93456,
    name: "fair-periodicalList"
  }, {
    path: "/fair/player",
    component: _318b1113,
    name: "fair-player"
  }, {
    path: "/fair/proxyExh",
    component: _0bda2bd0,
    name: "fair-proxyExh"
  }, {
    path: "/fair/service",
    component: _1c2da0d1,
    name: "fair-service"
  }, {
    path: "/fair/ticket",
    component: _011247c6,
    name: "fair-ticket"
  }, {
    path: "/fair/ticket_free",
    component: _01078307,
    name: "fair-ticket_free"
  }, {
    path: "/fair/ticket1",
    component: _21238d38,
    name: "fair-ticket1"
  }, {
    path: "/fair/ticket2",
    component: _21075e36,
    name: "fair-ticket2"
  }, {
    path: "/fair/top100",
    component: _6528398d,
    name: "fair-top100"
  }, {
    path: "/fair/topData",
    component: _ac9f16b2,
    name: "fair-topData"
  }, {
    path: "/home/data",
    component: _20e0d142,
    name: "home-data"
  }, {
    path: "/login/constant",
    component: _2c705ede,
    name: "login-constant"
  }, {
    path: "/manager/airTicket",
    component: _2482b04c,
    name: "manager-airTicket"
  }, {
    path: "/manager/auth",
    component: _5dcfed74,
    name: "manager-auth"
  }, {
    path: "/manager/fairFollow",
    component: _4f947e41,
    name: "manager-fairFollow"
  }, {
    path: "/manager/fairMy",
    component: _28e2d6bc,
    name: "manager-fairMy"
  }, {
    path: "/manager/fairRemind",
    component: _82714cd6,
    name: "manager-fairRemind"
  }, {
    path: "/manager/hotelMy",
    component: _9ad99938,
    name: "manager-hotelMy"
  }, {
    path: "/manager/industryMy",
    component: _655993f6,
    name: "manager-industryMy"
  }, {
    path: "/manager/newsFollow",
    component: _192aa330,
    name: "manager-newsFollow"
  }, {
    path: "/manager/newsMy",
    component: _011269aa,
    name: "manager-newsMy"
  }, {
    path: "/manager/periodicalMy",
    component: _f65381c4,
    name: "manager-periodicalMy"
  }, {
    path: "/manager/ticketMy",
    component: _9f4ac0b8,
    name: "manager-ticketMy"
  }, {
    path: "/manager/updateInfo",
    component: _8754e33a,
    name: "manager-updateInfo"
  }, {
    path: "/manager/updatePwd",
    component: _7d5f6cd0,
    name: "manager-updatePwd"
  }, {
    path: "/manager/usersFans",
    component: _1b397868,
    name: "manager-usersFans"
  }, {
    path: "/manager/usersFollow",
    component: _2f2aba3d,
    name: "manager-usersFollow"
  }, {
    path: "/manager/visa",
    component: _6666578d,
    name: "manager-visa"
  }, {
    path: "/manager/visitor",
    component: _09709252,
    name: "manager-visitor"
  }, {
    path: "/manager/visitorEdit",
    component: _68d5e27c,
    name: "manager-visitorEdit"
  }, {
    path: "/news/detail",
    component: _a356505a,
    name: "news-detail"
  }, {
    path: "/news/detail-seo",
    component: _2470eb63,
    name: "news-detail-seo"
  }, {
    path: "/news/exponews",
    component: _45b9a272,
    name: "news-exponews"
  }, {
    path: "/news/exponews-seo",
    component: _0592a857,
    name: "news-exponews-seo"
  }, {
    path: "/news/index2",
    component: _161971a2,
    name: "news-index2"
  }, {
    path: "/news/newsType",
    component: _8a85f994,
    name: "news-newsType"
  }, {
    path: "/news/strategy",
    component: _166a4f75,
    name: "news-strategy"
  }, {
    path: "/news/test",
    component: _d48963d8,
    name: "news-test"
  }, {
    path: "/pavilion/detail",
    component: _8eb309f4,
    name: "pavilion-detail"
  }, {
    path: "/pay/pay-periodical-mixin",
    component: _591f3795,
    name: "pay-pay-periodical-mixin"
  }, {
    path: "/pay/pay-ticket-mixin",
    component: _14735c4f,
    name: "pay-pay-ticket-mixin"
  }, {
    path: "/pay/payment",
    component: _38620b65,
    name: "pay-payment"
  }, {
    path: "/pay/result",
    component: _093094e4,
    name: "pay-result"
  }, {
    path: "/system/error",
    component: _5f1a95d2,
    name: "system-error"
  }, {
    path: "/visa/detail",
    component: _7fc4d4b6,
    name: "visa-detail"
  }, {
    path: "/visa/helper",
    component: _2b049e66,
    name: "visa-helper"
  }, {
    path: "/visa/pay-visa-mixin",
    component: _aed31012,
    name: "visa-pay-visa-mixin"
  }, {
    path: "/visa/payResult",
    component: _7b0c0a41,
    name: "visa-payResult"
  }, {
    path: "/visa/service",
    component: _d1b650d8,
    name: "visa-service"
  }, {
    path: "/visa/submitOrder",
    component: _7daeb01c,
    name: "visa-submitOrder"
  }, {
    path: "/visa/type",
    component: _ca121012,
    name: "visa-type"
  }, {
    path: "/visa/validate",
    component: _6ff70d65,
    name: "visa-validate"
  }, {
    path: "/wiki/detail",
    component: _39241254,
    name: "wiki-detail"
  }, {
    path: "/wiki/list",
    component: _2615d7e3,
    name: "wiki-list"
  }, {
    path: "/airTicket/component/Baggage",
    component: _fb53f00a,
    name: "airTicket-component-Baggage"
  }, {
    path: "/airTicket/component/CabinItem",
    component: _0561f897,
    name: "airTicket-component-CabinItem"
  }, {
    path: "/airTicket/component/ListFilter",
    component: _21e0184b,
    name: "airTicket-component-ListFilter"
  }, {
    path: "/airTicket/component/ListItem",
    component: _a55055b4,
    name: "airTicket-component-ListItem"
  }, {
    path: "/airTicket/component/RefundRule",
    component: _456823ae,
    name: "airTicket-component-RefundRule"
  }, {
    path: "/booth/components/card",
    component: _08332854,
    name: "booth-components-card"
  }, {
    path: "/company/component/pay",
    component: _9243c36c,
    name: "company-component-pay"
  }, {
    path: "/fair/component/booth",
    component: _172b2f3a,
    name: "fair-component-booth"
  }, {
    path: "/fair/component/booth1",
    component: _ce279444,
    name: "fair-component-booth1"
  }, {
    path: "/fair/component/content",
    component: _52596e66,
    name: "fair-component-content"
  }, {
    path: "/fair/component/detailTopBg",
    component: _67ee0bd4,
    name: "fair-component-detailTopBg"
  }, {
    path: "/fair/component/dialogSubsidy",
    component: _c2023320,
    name: "fair-component-dialogSubsidy"
  }, {
    path: "/fair/component/evaluation",
    component: _351d43df,
    name: "fair-component-evaluation"
  }, {
    path: "/fair/component/FairItemSeo",
    component: _4c99ed53,
    name: "fair-component-FairItemSeo"
  }, {
    path: "/fair/component/goodsDetail",
    component: _6cf11898,
    name: "fair-component-goodsDetail"
  }, {
    path: "/fair/component/invoice",
    component: _362a031a,
    name: "fair-component-invoice"
  }, {
    path: "/fair/component/newDetail",
    component: _68df02be,
    name: "fair-component-newDetail"
  }, {
    path: "/fair/component/newItem",
    component: _0ab97480,
    name: "fair-component-newItem"
  }, {
    path: "/fair/component/NewsItem2",
    component: _11b8cf79,
    name: "fair-component-NewsItem2"
  }, {
    path: "/fair/component/orderInfo",
    component: _296ccf6e,
    name: "fair-component-orderInfo"
  }, {
    path: "/fair/component/payDeposit",
    component: _38ec5079,
    name: "fair-component-payDeposit"
  }, {
    path: "/fair/component/poster",
    component: _da2af220,
    name: "fair-component-poster"
  }, {
    path: "/fair/component/resourceView",
    component: _165cfe36,
    name: "fair-component-resourceView"
  }, {
    path: "/fair/component/resourceView-copy",
    component: _3f472c48,
    name: "fair-component-resourceView-copy"
  }, {
    path: "/fair/component/Route",
    component: _2542d554,
    name: "fair-component-Route"
  }, {
    path: "/fair/component/ServerStep",
    component: _356a67f2,
    name: "fair-component-ServerStep"
  }, {
    path: "/fair/component/subsidy",
    component: _7bd466d0,
    name: "fair-component-subsidy"
  }, {
    path: "/fair/formData/nepcon",
    component: _20817a7c,
    name: "fair-formData-nepcon"
  }, {
    path: "/home/component/eveFair",
    component: _523c2600,
    name: "home-component-eveFair"
  }, {
    path: "/home/component/FairItem",
    component: _ba217382,
    name: "home-component-FairItem"
  }, {
    path: "/home/component/NewsList",
    component: _2e5d52f9,
    name: "home-component-NewsList"
  }, {
    path: "/login/component/bind",
    component: _08562c9f,
    name: "login-component-bind"
  }, {
    path: "/login/component/code",
    component: _c6cb4362,
    name: "login-component-code"
  }, {
    path: "/login/component/forget",
    component: _3213a7cf,
    name: "login-component-forget"
  }, {
    path: "/login/component/login",
    component: _53f6db12,
    name: "login-component-login"
  }, {
    path: "/login/component/password",
    component: _780fa206,
    name: "login-component-password"
  }, {
    path: "/login/component/register",
    component: _3c615f05,
    name: "login-component-register"
  }, {
    path: "/login/component/wechat",
    component: _8fade6f0,
    name: "login-component-wechat"
  }, {
    path: "/manager/components/AirTicketItem",
    component: _7625679c,
    name: "manager-components-AirTicketItem"
  }, {
    path: "/manager/components/AirTicketRepayment",
    component: _4f01ce8c,
    name: "manager-components-AirTicketRepayment"
  }, {
    path: "/manager/components/getIncoive",
    component: _9b1bfba4,
    name: "manager-components-getIncoive"
  }, {
    path: "/manager/components/HotelItem",
    component: _92ca804c,
    name: "manager-components-HotelItem"
  }, {
    path: "/manager/components/lookIncoive",
    component: _732da51b,
    name: "manager-components-lookIncoive"
  }, {
    path: "/manager/components/myUpload",
    component: _097804ec,
    name: "manager-components-myUpload"
  }, {
    path: "/manager/components/Repayment",
    component: _a1169134,
    name: "manager-components-Repayment"
  }, {
    path: "/manager/components/TicketItem",
    component: _270eee48,
    name: "manager-components-TicketItem"
  }, {
    path: "/manager/components/VisaItem",
    component: _6eb81b5e,
    name: "manager-components-VisaItem"
  }, {
    path: "/manager/components/VisaRepayment",
    component: _1663f725,
    name: "manager-components-VisaRepayment"
  }, {
    path: "/news/component/News",
    component: _f153b772,
    name: "news-component-News"
  }, {
    path: "/news/component/NewsItem",
    component: _5bb9d27a,
    name: "news-component-NewsItem"
  }, {
    path: "/news/component/RelatedNew",
    component: _14ad362e,
    name: "news-component-RelatedNew"
  }, {
    path: "/pay/component/poster",
    component: _62825bd0,
    name: "pay-component-poster"
  }, {
    path: "/visa/component/VisaMaterial",
    component: _30fca94e,
    name: "visa-component-VisaMaterial"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
